.Viewer {
  @include for-phone-only {
    padding: 24px;
  }

  @include for-tablet-portrait-up {
    padding: 24px;
  }

  @include for-desktop-up {
    padding: 38px 130px;
  }

  &__Container {
    margin: 0 auto;
    @include for-phone-only {
      width: 312px;
    }

    @include for-tablet-portrait-up {
      width: 720px;
    }

    @include for-desktop-up {
      width: 1020px;
    }
  }

  &__Title {
    font-family: $font;
    color: $text-secondary;
    @include md-16-extrabold;
  }

  &__Name {
    font-family: $font;
    color: $text-primary;
    @include md-21-extrabold;
  }

  &__Wrapper {
    @include for-phone-only {
      margin-top: 16px;
      width: 312px;
      height: 504px;
    }

    @include for-tablet-portrait-up {
      margin-top: 32px;
      width: 720px;
      height: 720px;
    }

    @include for-desktop-up {
      margin-top: 16px;
      width: 1020px;
      height: 561px;
    }
  }
}
