.ResetPasswordPage_background {
  background-image: url('../../public/images/black_background.png');
  position: absolute;
  margin: 0;
  width: 100vw;
  height: 100vh;
}
.ResetPasswordPage {
  background-color: white;
  padding: 40px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  border-radius: 16px;

  .input {
    margin-top: 28px;
  }


  &__header {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    position: relative;
    margin-bottom: 11px;

    button {
      position: absolute;
      right: 0;
      color: #646466;
    }
  }

  &__inner {
    position: relative;
    height: calc(100% - 36px);

    &__header {
      font-family: $font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: #646466;
      margin-bottom: 24px;
    }

    &__input {
      margin-bottom: 24px;

      .input {
        margin-top: 8px;
      }

      .margin-top-12px {
        margin-top: 12px;
      }

      .fail {
        border-color: #e94a51;
        color: #e94a51;
      }

      &__header {
        font-family: $font;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.25px;
        color: #000000;
      }
    }

    &__text {
      margin-top: 6px;
      font-family: $font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: #646466;
    }

    .text-fail {
      color: #e94a51;
    }

  }

  &__email {
    margin: 19px 0 19px 0;
    font-family: $font;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #6073ff;
  }

  &__text {
    font-family: $font;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #646466;
  }
}
.ResetPasswordPage_Complete {
  background-color: white;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  height: 268px;
  border-radius: 16px;
  padding: 28px;
  &__header {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    position: relative;
  }
}
