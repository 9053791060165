.PaymentHistory {
  &__info {
    padding: 50px 8.3333%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 25px;

    &__box {
      display: flex;
      padding: 24px;
      gap: 35px;
      width: 514px;
      height: 174px;

      background: $grey-10;
      border-radius: 24px;
      font-family: $font;
      font-style: normal;

      &__item {
        gap: 8px;
        &__label {
          @include sm-14-extrabold;
          color: $grey-950;
        }

        &__detail {
          @include md-16-semibold;
          color: $grey-950;
        }

        &__list {
          display: flex;
          gap: 8px;
          color: $grey-600;
          @include sm-12-semibold;
        }
      }
    }
  }

  &__datatable {
    padding: 0 8.3333%;
    margin: 60px 0;
  }
}
