body {
  margin: 0;
  display: flex;
  font-weight: 400;
  font-family: 'Noto Sans', sans-serif;
  background-color: #f6f6fa;
  height: 100vh;
}

#root {
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media screen and (min-width: 360px) {
  .container,
  .container-fluid {
    padding: 0 24px;
  }
}

@media screen and (min-width: 1800px) {
  .container,
  .container-fluid {
    padding: 0 36px;
  }
}
