.UploadWaiting {
  padding: 10px 24px;

  progress::-webkit-progress-bar {
    background-color: #e1e2e5;
    border-radius: 2px;
  }

  progress::-webkit-progress-value {
    background: linear-gradient(90deg, #6073ff 0%, #60ecff 100%) !important;
    border-radius: 2px;
  }

  &__header {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__name {
      font-family: $font;
      font-style: normal;
      color: $text-primary;
      @include sm-14-medium;
    }

    &__wrapper {
      display: flex;
      align-items: center;

      &__volume {
        font-family: $font;
        font-style: normal;
        color: $text-secondary;
        @include sm-14-semibold;
      }
    }
  }

  &__state-en {
    margin-top: 4px;
    height: 22px;
    display: flex;
    gap: 4px;
    align-items: center;
    font-family: $font;
    font-style: normal;
    color: $text-placeholder;
    @include sm-12-semibold;

    progress {
      width: 396px;
      height: 4px;
    }
  }

  &__state {
    margin-top: 4px;
    height: 22px;
    display: flex;
    align-items: center;
    font-family: $font;
    font-style: normal;
    color: $text-placeholder;
    @include sm-12-semibold;

    &__text {
      width: 40px;
    }

    &__text-en {
      width: 44px;
    }

    progress {
      width: 404px;
      height: 4px;
    }
  }

  &__create-en {
    margin-top: 4px;
    height: 22px;
    display: flex;
    gap: 4px;
    align-items: center;
    font-family: $font;
    font-style: normal;
    color: $text-placeholder;
    @include sm-12-semibold;

    &__state {
      &__text-en {
        width: 67px;
      }
    }

    &__progress-en {
      width: 372px;
      height: 4px;
      border-radius: 2px;
      background: linear-gradient(90deg, #6073ff, #60ecff, #6073ff);
      background-size: 200%;
      animation: gradient 2s linear infinite;
    }
  }

  &__create {
    margin-top: 4px;
    height: 22px;
    display: flex;
    align-items: center;
    font-family: $font;
    font-style: normal;
    color: $text-placeholder;
    @include sm-12-semibold;

    &__text {
      width: 40px;
    }

    &__progress {
      width: 404px;
      height: 4px;
      border-radius: 2px;
      background: linear-gradient(90deg, #6073ff, #60ecff, #6073ff);
      background-size: 200%;
      animation: gradient 2s linear infinite;
    }

    @keyframes gradient {
      0% {
        background-position: 0% 0%;
      }
      0% {
        background-position: 200% 0%;
      }
    }
  }
}

.Separator {
  border-top: 2px solid $bg-lightgrey;
}
