.Agreement {
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  &__inner {
    width: 384px;
    height: 100%;
    padding-top: 122px;
    margin: 0 auto;

    &__formerpage__container {
      top: 85px;
      position: absolute;
      margin-bottom: 12px;
    }
    &__formerpage {
      color: #646466;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__header {
      font-family: $font;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 46px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: #0c0d0d;
    }

    &__body {
      &__button {
        margin-top: 25px;
      }

      .Checkbox {
        margin-top: 40px;
        padding: 30px 35px;
        border: 1px solid #aeafb2;
        border-radius: 10px;

        &__separator {
          margin-top: 10px;
          margin-bottom: 10px;
          width: 315px;
          height: 1px;
          background-color: #d9d9d9;
        }

        &__wrapper {
          color: #646466;
          font-family: 'Pretendard';
          font-size: 16px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: 0.25px;
          text-align: center;

          margin-top: 14px;
          display: flex;

          &__show {
            flex: 1;
            text-align: right;

            button {
              font-family: $font;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 28px;
              letter-spacing: 0.1px;
              text-decoration-line: underline;
              color: #646466;
            }
          }
        }
      }

      .Usertag {
        margin-top: 25px;

        &__header {
          font-family: $font;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: 0.25px;
          color: #0c0d0d;
        }

        &__body {
          margin-top: 25px;
          padding: 18px;
          width: 384px;
          background: #f6f6fa;
          border-radius: 10px;

          &__icons {
            text-align: center;
          }

          &__input {
            padding: 14px 0px 17px 0px;
          }
        }

        &__skip {
          margin-top: 12px;
          text-align: right;

          button {
            font-family: $font;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
            letter-spacing: 0.1px;
            color: #6073ff;
            flex: none;
            order: 0;
            flex-grow: 0;
          }
        }
      }
    }

    &__footer {
      margin-top: 24px;
      text-align: center;
      font-family: $font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.1px;
      color: #000000;

      &__login {
        margin-left: 12px;
        color: #6073ff;
      }
    }
  }
}
