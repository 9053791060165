.DetailViewerGuide {
  margin-top: 160px;
  font-family: $font;
  font-style: normal;

  &__Title {
    color: $text-primary;
    @include lg-40-extrabold;
  }

  &__Sub {
    color: $text-primary;
    @include md-18-bold;
  }

  &__Wrapper {
    margin-top: 40px;
    width: 100%;
    background: #ffffff;
    border-radius: 24px;
    padding: 32px 40px;

    &__Header {
      text-align: center;
      @include md-18-extrabold;
    }

    &__Buttons {
      margin-top: 32px;
      display: flex;
      gap: 32px;
      justify-content: center;

      .GuideButton {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 2px solid #ffffff;
        @include sm-12-semibold;
      }

      .select {
        border: 2px solid $states-secondary-normal;
      }
    }

    &__Arrow {
      margin-top: 24px;
      display: flex;
      justify-content: center;
    }
  }

  &__Contents {
    &__Explain {
      display: flex;
      justify-content: center;
      font-family: $font;
      font-style: normal;
      color: $text-secondary;
      @include md-16-semibold;

      &__Highlight {
        background: rgba(112, 230, 246, 0.5);
        border-radius: 4px;
        @include md-16-extrabold;
      }
    }

    &__Wrapper {
      margin-top: 24px;
      background: #e1e2e5;
      border-radius: 24px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      display: flex;
      padding: 30px;
      gap: 30px;

      &__Tap {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 16px;
        width: 125px;
        padding: 16px;
        gap: 28px;
        font-family: $font;

        &__Item {
          width: 93px;
          background: #ffffff;
          border-radius: 8px;
          padding: 8px;
          cursor: pointer;
          color: $text-placeholder;
          @include sm-14-bold;

          &__Bold {
            @include md-18-extrabold;
          }
        }

        .selected {
          color: $text-primary;
          background: #eeeff2;
        }
      }

      &__Contents {
        display: flex;
        flex-direction: column;

        &__Text {
          display: flex;
          gap: 32px;
          align-items: center;

          &__Number {
            color: #ffffff;
            @include lg-70-extrabold;
          }

          &__Sub {
            @include lg-24-bold;

            &__Explain {
              color: #646466;
              @include md-18-bold;
            }

            &__Explain2 {
              color: #646466;
              @include sm-14-semibold;
            }
          }
        }

        &__Explain {
          position: absolute;
          top: 20px;
          right: 40px;
          color: $text-secondary;
          @include sm-12-medium;
        }
      }

      &__ButtonText {
        display: flex;
        margin-left: 130px;
        align-items: center;
        gap: 10px;
        font-family: $font;
        color: $text-secondary;
        @include sm-12-medium;
      }
    }
  }
}
