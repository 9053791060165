.Dashboard__navigator {
  width: 200px;
  background-color: #ffffff;
  padding: 10px 24px;
  border-right: 1px solid #0c0d0d14;

  @include for-phone-only {
    border: none;
  }

  &__header {
    font-family: $font;
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #bbbbbf;
  }

  &__separator {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 152px;
    height: 1px;
    background: #0c0d0d14;
  }
}
.LogoutModal {
  width: 480px;
  border-radius: 16px;
  padding: 18px 24px 10px 24px;

  &__title {
    font-family: 'Pretendard';
    font-size: 21px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-bottom: 30px;
  }
  &__button {
    display: flex;
    justify-content: right;
    margin-bottom: 10px;
  }
}
