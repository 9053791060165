.ModelOverview {
  width: 100%;
  height: 100vh;
  font-family: $font;
  font-style: normal;

  &__Title {
    margin-left: 28px;
    color: $text-secondary;
    @include md-18-extrabold;
  }

  &__Wrapper {
    margin-top: 22px;
    display: flex;
    gap: 49px;

    &__3Dviewer {
      &__Header {
        width: 430px;
        color: $text-primary;
        @include lg-24-bold;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__Viewer {
        margin-top: 16px;
        height: 318px;
        background: #ffffff;
        border-radius: 12px;

        .rdsfont {
          height: 100%;
        }
      }

      &__Export {
        margin-top: 16px;

        &__Text {
          font-family: $font;
          color: $text-secondary;
          @include sm-12-semibold;
        }

        &__Buttons {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
      }
    }

    &__ModelInfo {
      flex: 1;

      .RDSInputFieldUnderline__wrap {
        padding: 0;
      }

      .RDSInputFieldUnderline__inputwrap__length {
        display: none;
      }

      &__DetailInfo {
        margin-top: 4px;
        position: relative;
        display: flex;
        padding: 15px 28px;
        background: #ffffff;
        border-radius: 12px;
        gap: 70px;

        &__Byte {
          display: flex;
          flex-direction: column;
          gap: 8px;

          &__Text {
            display: flex;
            gap: 20px;
          }
        }

        &__Control {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 8px;
        }
      }

      &__Wrapper {
        margin-top: 26px;
        display: flex;
        gap: 43px;
      }

      &__ARViewer {
        background: #ffffff;
        border-radius: 12px;
        padding: 24px 28px;
        flex: 2;

        &__Title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include lg-24-bold;
        }
      }

      &__GIF {
        padding: 24px 28px;
        flex: 1;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 12px;
        gap: 16px;

        &__Text {
          position: relative;
          @include lg-24-bold;
        }
      }
    }
  }
}

.MD-16-BOLD {
  @include md-16-bold;
}

.MD-16-BOLD-en {
  font-size: 15px;
}

.MD-16-MEDIUM {
  @include md-16-medium;
}
