.Catalog {
  display: flex;
  flex-direction: column;

  &__Header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    background: black;
    font-family: $font;
    font-style: normal;
    color: white;
    @include sm-12-medium;
  }

  &__Container {
    padding-top: 50px;
    margin: 0 auto;

    &__Title {
      font-family: $font;
      font-style: normal;
      @include md-21-extrabold;
    }

    &__Sub {
      font-family: $font;
      font-style: normal;
      @include sm-14-bold;
    }

    &__Contents {
      margin-top: 48px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 103px;
      column-gap: 87px;
    }
  }

  &__Pagination {
    margin-top: 62px;
    text-align: center;

    &__Footer {
      margin-top: 73px;
      margin-bottom: 50px;
      padding-top: 24px;
      border-top: 1px solid rgba(12, 13, 13, 0.08);
      font-family: $font;
      font-style: normal;
      color: $bi-primary1;
      @include md-18-bold;
    }
  }
}

.CatalogModelCard {
  width: 328px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__Paper {
    width: 328px;
    height: 246px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
    border-radius: 16px;
    position: relative;

    &__Icon {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__Info {
    margin-top: 24px;
    width: 328px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__Title {
      font-family: $font;
      font-style: normal;
      color: $text-primary;
      @include sm-14-bold;
    }

    &__Text {
      white-space: pre-line;
      word-break: break-all;
      font-family: $font;
      font-style: normal;
      @include sm-12-medium;
    }
  }
}

.CatalogModelViewer {
  width: 1152px;
  height: 475px;
  display: flex;
  flex-direction: row;
  gap: 98px;

  &__Wrapper {
    position: relative;
  }

  &__Text {
    font-family: $font;
    font-style: normal;
    color: $text-secondary;
    @include md-18-extrabold;
    pointer-events: auto;
  }

  &__Dropdown {
    margin-top: 20px;
    pointer-events: auto;
  }

  &__Button {
    position: absolute;
    bottom: 0;
    pointer-events: auto;
  }

  &__Viewer {
    width: 100%;
    height: calc(100% - 27px);
    pointer-events: auto;
  }
}

.GuideModal {
  display: flex;
  flex-direction: row;
  margin-left: 350px;
  align-items: center;

  &__CloseButton {
    display: flex;
    justify-content: right;
  }
}

.ARViewerModal {
  width: 300px;
  height: 575px;
  text-align: center;
  padding: 6px;

  &__Title {
    font-family: $font;
    font-style: normal;
    background: linear-gradient(90deg, #6073ff 0%, #60ecff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    @include lg-28-extrabold;
  }

  &__SubText {
    margin-top: 8px;
    font-family: $font;
    font-style: normal;
    color: $text-primary;
    @include md-16-bold;
  }

  &__QRCode {
    margin-top: 8px;
    display: inline-block;
    margin-bottom: 16px;
    border: 2px solid transparent;
    border-radius: 8.67px;
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #6073ff 0%, #60ecff 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;

    &__Wrapper {
      padding: 16px;
      height: 283px;
    }
  }

  &__SupportingText {
    text-align: left;
    font-family: $font;
    font-style: normal;
    color: $text-primary;
    @include sm-14-bold;
  }

  &__Footer {
    margin-top: 8px;
    text-align: left;
    font-family: $font;
    font-style: normal;
    color: $text-secondary;
    @include sm-12-medium;
  }

  &__Button {
    display: flex;
    justify-content: flex-end;
  }
}

.ARViewerModal-en {
  width: 400px;
  height: 600px;
}
