.ResetPassword {
  width: 400px;
  padding: 28px;

  .input {
    margin-top: 28px;
  }

  &__header {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    position: relative;

    button {
      position: absolute;
      right: 0;
      color: #646466;
    }
  }

  &__email {
    margin: 19px 0 19px 0;
    font-family: $font;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #6073ff;
  }
  &__sendButton {
    margin-top: 15px;
  }

  &__text {
    margin-top: 20px;
    font-family: $font;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #0c0d0d;
  }
  &__resendEmail {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #646466;
    margin-top: 20px;
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.1px;
    text-align: left;
  }
}

.bigger {
  height: 468px;
}
