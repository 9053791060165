.notice {
  width: 480px;
  padding: 24px;
  &__title {
    font-family: 'Pretendard';
    font-size: 21px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-bottom: 4px;
  }
  &__contents {
    font-family: 'Pretendard';
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    margin-bottom: 24px;
  }
  &__cancelButton {
    margin-top: 8px;
  }
  &__survey {
    &__text {
      font-family: 'Pretendard';
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
    }
    &__multipleSelect {
      font-family: 'Pretendard';
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      text-align: right;
      color: #646466;
      margin-bottom: 5px;
    }
    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 20px;
      gap: 12px;
      /* background/light_grey */
      background: #f6f6fa;
      border-radius: 8px;
      margin-bottom: 24px;      
    }
    &__afterCall {
      margin-bottom: 12px;
    }
  }
  &__complete{
    &__image{
    margin-bottom: 16px;
  }}
}
