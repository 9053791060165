.Payment {
  padding-top: 42px;
  background: #ffffff;
  height: 100vh;

  .RDSIconButton {
    background-color: $states-darkgrey-normal;
  }

  &__ContentsWrapper {
    position: relative;
  }

  &__BackButton {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 10;
  }

  &__NextButton {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 10;
  }

  &__visible {
    height: 90%;
  }
}
