.PlicarIntro {
  // border: 1px solid red;
  padding: 0;
  margin-top: 66px;
  font-family: $font;
  font-style: normal;
  color: $text-primary;

  header {
    text-align: right;
    @include md-16-semibold;

    @include for-phone-only {
      padding: 0 24px;
    }
  }

  &__highlight {
    color: $states-primary-normal;
  }

  &__body {
    margin-top: 68px;
  }

  &__3d {
    text-align: center;

    @include for-phone-only {
      padding: 0 24px;
    }

    &__title {
      color: $bi-primary1;
      @include md-21-extrabold;

      @include for-phone-only {
        @include md-16-extrabold;
      }
    }

    &__sub {
      @include lg-40-extrabold;

      @include for-phone-only {
        @include md-21-extrabold;
      }
    }

    &__tabs {
      height: 317px;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        margin-top: 36px;
        display: flex;
        gap: 30px;
        flex-direction: column;
        align-items: flex-start;
        color: $text-placeholder;
        @include lg-24-extrabold;

        @include for-phone-only {
          text-align: left;
          @include md-16-extrabold;
        }
      }
    }
  }

  &__Function {
    margin-top: 180px;
    text-align: center;

    @include for-phone-only {
      padding: 0 24px;
    }

    &__title {
      margin: 0 auto;
      width: 270px;
      position: relative;
      color: $text-primary;
      @include lg-40-extrabold;

      @include for-phone-only {
        width: 200px;
        @include lg-28-extrabold;
      }

      &__background {
        position: absolute;
        bottom: 0;
        left: -10px;
        width: 290px;
        height: 24px;
        opacity: 0.3;
        background: $bi-gradation1;

        @include for-phone-only {
          left: 0;
          width: 200px;
        }
      }
    }

    &__titleEn {
      width: 552px;

      &__background {
        width: 570px;
      }
    }

    &__Wrapper {
      margin-top: 65px;

      &__Text {
        &__sm {
          @include md-18-extrabold;

          @include for-phone-only {
            @include md-16-extrabold;
          }
        }
        &__lg {
          margin-bottom: 36px;
          @include lg-28-extrabold;

          @include for-phone-only {
            margin-bottom: 19px;
            @include md-21-extrabold;
          }
        }
      }
    }
  }

  &__Button {
    margin-top: 68px;

    @include for-phone-only {
      margin-top: 78px;
    }
  }

  &__Commerce {
    text-align: center;
    margin-top: 60px;

    @include for-phone-only {
      padding: 0 24px;
    }

    &__Title {
      @include lg-28-extrabold;
    }

    &__Sub {
      margin-top: 10px;
      @include md-18-medium;

      @include for-phone-only {
        text-align: left;
      }
    }

    &__Images {
      padding: 0 115px;
      margin-top: 60px;
      display: flex;
      justify-content: center;
      gap: 30px;

      @include for-phone-only {
        flex-direction: column;
        padding: 0;
        align-items: center;
      }
    }

    &__ImageWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 244px;
      text-align: left;
      color: $text-secondary;
      @include md-16-semibold;
    }
  }

  &__DataAnalysis {
    margin-top: 85px;
  }

  &__ButtonsArea {
    margin-top: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__Text {
      @include lg-32-extrabold;

      @include for-phone-only {
        @include lg-24-extrabold;
      }
    }
  }

  &__Guide {
    display: flex;
    justify-content: flex-end;
    margin-right: 111px;

    @include for-phone-only {
      margin-top: 38px;
      margin-right: 74px;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 72px 0 32px 0;
    padding: 10px;
    color: $text-secondary;
    gap: 10px;
    @include sm-12-medium;

    @include for-phone-only {
      flex-direction: column;
    }

    .separator {
      width: 2px;
      height: 16px;
      background: $text-placeholder;

      @include for-phone-only {
        display: none;
      }
    }

    .PlicarIntro__Footer__buttons {
      display: flex;
      gap: 26px;
    }
  }
}
