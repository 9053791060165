.SelectableIconTextButton {
  border: 1px solid #ffffff;
  margin-right: 17px;
  margin-bottom: 17px;
  background: #ffffff;
  border: none;
  border-radius: 20px;
  font-family: 'Pretendard', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #6073ff;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding: 6px 10px;
  width: auto;

  &__IconArea {
    display: flex;
    align-items: center;
  }
}

.Selected {
  border: 1px solid #6073ff;
  background: #f6f6fa;
}
