.CatalogList {
  height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;

  &__Title {
    font-family: $font;
    font-style: normal;
    color: $text-primary;
    @include md-21-extrabold;
  }

  &__SubText {
    font-family: $font;
    font-style: normal;
    color: $text-secondary;
    @include sm-14-semibold;
  }

  .DataTable {
    width: 100%;
    border-radius: 8px 8px 0 0;

    nav.rdt_Pagination {
      border-radius: 0 0 8px 8px;
    }

    .rdt_TableHead .rdt_TableCol_Sortable {
      font-family: $font;
      font-style: normal;
      color: $text-secondary;
      @include sm-12-medium;
    }

    .rdt_TableBody .rdt_TableCell {
      font-family: $font;
      font-style: normal;
      color: $text-primary;
      @include sm-14-medium;
    }

    .rdt_TableRow:hover {
      background: #f6f6fa;
    }
  }

  .BorderRadius {
    border-radius: 8px;
  }

  &__Footer {
    margin-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: right;

    &__Wrapper {
      width: 736px;
      background-color: #ffffff;
      border-radius: 16px;

      &__Text {
        display: flex;
        align-items: center;
        margin: 21px 24px;
        font-family: $font;
        font-style: normal;
        color: $text-secondary;
        @include sm-14-bold;
      }
    }
  }
}

.CatalogTempSaved {
  display: flex;
  gap: 8px;
  font-family: $font;
  font-style: normal;
  color: $text-disabled;
  @include sm-14-medium;
}

.CatalogPublished {
  display: flex;
  gap: 8px;
  font-family: $font;
  font-style: normal;
  color: $notification-success-main;
  @include sm-14-medium;
}

.CatalogStopPublish {
  display: flex;
  gap: 8px;
  font-family: $font;
  font-style: normal;
  color: $notification-caution-main;
  @include sm-14-medium;
}

.FreeCatalog {
  padding-top: 50px;
  margin: 0 auto;
  width: 333px;

  &__Title {
    font-family: $font;
    font-style: normal;
    color: $text-primary;
    @include md-21-extrabold;
  }

  &__Subtext {
    margin-top: 25px;
    font-family: $font;
    font-style: normal;
    color: $text-secondary;
    @include sm-14-semibold;
  }
}
