.Modelinfocard {
  margin-top: 18px;
  display: flex;
  background: $bg-white;
  border-radius: 16px;
  gap: 16px;

  svg {
    cursor: pointer;
  }

  &__info {
    &__title {
      display: flex;
      font-family: $font;
      font-style: normal;
      align-items: center;
      color: $text-primary;
      gap: 16px;
      @include md-21-extrabold;

      svg.checkout {
        background: $bi-primary4;
        border-radius: 6px;
      }

      input {
        font-family: $font;
        font-style: normal;
        display: flex;
        align-items: center;
        color: $text-primary;
        @include md-21-extrabold;
      }
    }

    &__text {
      margin-top: 4px;
      font-family: $font;
      font-style: normal;
      display: flex;
      align-items: center;
      color: $text-primary;
      gap: 16px;
      @include md-16-semibold;

      svg.checkout {
        background: $bi-primary4;
        border-radius: 6px;
      }

      input {
        font-family: $font;
        font-style: normal;
        display: flex;
        align-items: center;
        color: $text-secondary;
        @include md-16-medium;
      }

      &__link {
        color: $text-secondary;
      }
    }
  }

  &__switch {
    font-family: $font;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $text-secondary;
    margin: auto;
    @include sm-14-semibold;
  }
}

@media screen and (min-width: 360px) {
  .Modelinfocard {
    padding: 16px;
    flex-direction: column;

    &__info {
      &__title {
        @include md-18-bold;

        input {
          @include md-18-bold;
        }
      }
    }

    &__image {
      border-radius: 8px;
      margin: 0 auto;

      img {
        border-radius: 8px;
        min-width: 100%;
        max-width: none;
      }
    }

    &__switch {
      flex-direction: row;
      margin: 0;
      gap: 10px;
      @include sm-14-semibold;
    }
  }
}

@media screen and (min-width: 768px) {
  .Modelinfocard {
    flex-direction: row;
    padding: 16px 24px;

    &__image {
      width: 140px;
      border-radius: 8px;
      margin: 0;
    }

    img {
      max-width: 140px;
    }

    &__switch {
      flex-direction: column;
      margin: auto;
      gap: 0;
      @include sm-12-semibold;
    }
  }
}

@media screen and (min-width: 1280px) {
  .Modelinfocard {
    flex-direction: row;
    padding: 16px 24px;

    &__image {
      width: 140px;
      border-radius: 8px;
      margin: 0;

      img {
        max-width: 140px;
      }
    }

    &__switch {
      flex-direction: column;
      margin: auto;
      color: $text-primary;
      gap: 8px;
      @include md-16-extrabold;

      &__Explain {
        color: $text-secondary;
        @include sm-12-semibold;
      }
    }
  }
}
