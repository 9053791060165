.CatalogDetailHeader {
  height: 72px;
  background: #ffffff;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 8px 8px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__Left {
    display: flex;
  }

  &__Right {
    display: flex;
  }
}

.CatalogDetail {
  height: calc(100% - 72px);
  overflow-y: scroll;

  > div {
    width: 1024px;
    margin: 0 auto;
  }

  &__Options {
    margin-top: 40px;
    background: #eeeff2;
    border-radius: 16px;
    padding: 28px 102px;
    display: flex;
    justify-content: space-between;
    font-family: $font;

    &__Sales {
      &__Title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $text-primary;
        @include md-16-bold;
      }

      &__Sub {
        color: $text-secondary;
        @include sm-12-medium;
      }
    }

    &__i18n {
      &__Title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: $font;
        font-style: normal;
        @include md-16-bold;
      }

      &__Subtext {
        width: 350px;
        font-family: $font;
        font-style: normal;
        @include sm-12-medium;
      }
    }
  }

  &__Wrapper {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__NoData {
      width: 100%;
      height: 300px;
      background: #ffffff;
      box-shadow: 0px 8px 24px rgba(96, 115, 255, 0.3);
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__Buttons {
    margin-top: 55px;
    display: flex;
    justify-content: flex-end;
  }

  &__Contents {
    padding-bottom: 40px;

    &__Wrapper {
      display: grid;
      margin-top: 40px;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 5.3125%;
      row-gap: 40px;

      &__AddModels {
        border: 2px dashed #6073ff;
        height: 222px;
        margin-top: 66px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.ModelsWrapper {
  overflow-y: scroll;
  max-height: 345px;
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(4, minmax(50px, 1fr));
  column-gap: 24px;
  row-gap: 40px;
}

.NoModels {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
