.UploadFailed {
  padding: 10px 24px 14px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__name {
    font-family: $font;
    font-style: normal;
    color: $text-primary;
    @include sm-14-medium;
  }

  &__cause {
    margin-top: 8px;
    display: flex;
    gap: 5px;
    align-items: center;
    font-family: $font;
    font-style: normal;
    color: $notification-warning-main;
    @include sm-14-semibold;

    &__plan {
      color: $notification-caution-main;
    }
  }
}

.Separator {
  border-top: 2px solid $bg-lightgrey;
}

.plan {
  background: $notification-caution-light;
}
