.Modeldetail {
  padding-top: 174px;
  padding-bottom: 126px;

  &__ResponsiveWrapper {
    @media screen and (min-width: 1800px) {
      padding: 0 232px;
    }
  }

  &__inner {
    &__buttons {
      display: flex;
    }

    &__3Dviewer {
      scroll-margin: 64px;
    }

    &__3DGIF {
      margin-top: 140px;
      scroll-margin: 200px;

      .Detail3DGIF {
        &__title {
          font-family: $font;
          font-style: normal;
          color: rgba(0, 0, 0, 0.87);
          @include lg-24-extrabold;
        }

        &__contents {
          margin-top: 34px;
          height: calc(100% - 72px);

          &__viewer {
            position: relative;
            background: $bg-white;
            border-radius: 8px;
            filter: drop-shadow(0px 8px 16px rgba(96, 115, 255, 0.16));
            aspect-ratio: 1;

            button.RDSButton {
              height: 50px;
              margin: 0 auto;
            }

            &__paper {
              margin: 0 auto;
              margin-top: 25%;
              width: 50%;
              aspect-ratio: 1;
              background: $bg-white;
              display: flex;
              justify-content: center;
              align-items: center;

              &__waiting {
                @include md-21-bold;
              }
            }

            &__button {
              width: 207px;
              height: 50px;
              font-family: $font;
              position: absolute;
              left: calc(50% - 103.5px);
              bottom: 30px;
            }
          }
        }
      }
    }

    &__others {
      margin-top: 140px;

      .OtherModels {
        &__title {
          font-family: $font;
          font-style: normal;
          display: flex;
          align-items: center;
          color: $text-primary;
          @include lg-24-extrabold;
        }
      }
    }
  }
}

.ModeldetailHeader {
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 110px;
  background: #ffffff;

  &__Wrapper {
    height: 100%;
    position: relative;

    &__Buttons {
      display: flex;
      justify-content: space-between;
      padding: 20px 10% 0 10%;
    }

    &__Tabs {
      padding: 0 10%;
      position: absolute;
      bottom: -5px;
      width: 100%;
    }
  }
}

.Modeldetail.container-fluid {
  padding-left: 12px;
  padding-right: 12px;
}
