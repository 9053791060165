.CatalogEditItem {
  &__Wrapper {
    display: flex;
    flex-direction: column;

    &__Control {
      display: flex;
      justify-content: space-between;
    }

    &__Viewer {
      margin-top: 24px;
      width: 100%;
      height: 222px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 8px 16px rgba(96, 115, 255, 0.16);

      &__Thumbnail {
        width: 80%;
        height: 100%;
        margin: 0 auto;
      }
    }

    &__Text {
      margin-top: 24px;

      &__Header {
        margin: 0 16px;
        font-family: $font;
        font-style: normal;
        color: $text-secondary;
        @include md-16-bold;
      }
    }
  }
}
