.DetailModelCount {
  margin-top: 160px;
  font-family: $font;
  font-style: normal;

  &__Title {
    color: $text-primary;
    @include lg-40-extrabold;
  }

  &__SubText {
    color: $text-secondary;
    @include sm-14-semibold;
  }

  &__ChartWrapper {
    margin-top: 16px;
    background: white;
    width: 100%;
    aspect-ratio: 1.8747;
    min-height: 455px;
    max-height: 575px;
  }

  &__Radio {
    display: flex;
    gap: 32px;
    padding: 10px 0;
    margin-top: 8px;
  }
}
