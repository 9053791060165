@mixin for-big-desktop {
  @media (min-width: 1670px) {
    @content;
  }
}

.Main {
  &__Text {
    margin-top: 70px;
    margin-left: 40px;
    margin-bottom: 18px;

    font-style: normal;
    font-family: $font;
    color: $text-primary;
    &__Title {
      @include md-21-bold;
    }

    &__Sub {
      @include md-16-semibold;
    }
  }

  &__Upload {
    position: relative;
    min-height: 533px;
    max-height: 650px;
    aspect-ratio: 0.7842;
    border: 1px dashed $bg-lightgrey;
    background-image: linear-gradient(90deg, #6073ff 0%, #60ecff 100%);
    border-radius: 26px;
    display: flex;
    flex-direction: column;

    @include for-desktop-up {
      width: 100%;
    }

    &__Dragover {
      position: absolute;
      inset: 0;
      background: rgba(35, 50, 152, 0.13);
      border-radius: 24px;
    }

    &__DragLeave {
      display: none;
    }

    &__Img {
      border-radius: 24px 24px 0 0;
      flex: 1;
      background-image: url('../images/uploadbackground.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__Text {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      font-style: normal;
      font-family: $font;
      color: $text-secondary;
      background-color: $bg-lightgrey;
      border-radius: 0 0 24px 24px;
      @include sm-14-semibold;

      &__Wrapper {
        margin-top: 47px;
      }
    }

    &__footer {
      position: absolute;
      bottom: 20px;
      color: $bi-primary1;
    }
  }

  &__Viewer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    aspect-ratio: 0.9493;
    min-height: 533px;
    max-height: 650px;

    &__Wrapper {
      position: relative;
      height: 100%;
      background-color: white;
      border-radius: 16px;
    }

    &__Text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-style: normal;
      font-family: $font;
      color: #7d7d80;
      z-index: 5;
      @include md-16-bold;
    }

    &__QRCode {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: #e1e2e5;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
    }

    img {
      width: 100%;
    }

    &__GIFWrapper {
      position: relative;
      height: 100%;
      background-color: white;
      border-radius: 16px;
    }

    &__Catalog {
      cursor: pointer;
    }
  }
}
