.Signup {
  &__container {
    margin-top: 35px;
  }
  &__emailSentModal {
    width: 480px;
    border-radius: 16px;
    padding: 24px 24px 10px 24px;

    &__title {
      font-family: 'Pretendard';
      font-size: 21px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0.25px;
      text-align: left;
      margin-bottom: 10px;
    }
    &__content {
      font-family: 'Pretendard';
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      margin-bottom: 25px;
    }
    &__button{
      display: flex;
      justify-content: right;
      margin-bottom: 10px;
    }
  }
}
