.Models {
  overflow: auto;

  &__inner {
    margin: 50px 0;

    &__title {
      font-family: $font;
      font-style: normal;
      color: $text-primary;
      @include md-21-extrabold;
    }

    &__text {
      font-family: $font;
      font-style: normal;
      color: $text-secondary;
      @include sm-14-semibold;
    }

    &__wrapper {
      margin-top: 40px;

      &__models {
        display: grid;
        grid-template-columns: repeat(5, minmax(50px, 1fr));
        column-gap: 16px;
        row-gap: 32px;
      }

      &__nomodels {
        display: flex;
        width: 100%;
        height: 50vh;
        justify-content: center;
        align-items: center;
        font-family: $font;
        font-style: normal;
        color: $text-placeholder;
        text-align: center;
        @include sm-14-semibold;
      }

      &__i18n {
        margin: 50px 0;
      }

      &__pagination {
        text-align: right;

        .RDSPagination__number {
          margin-top: 50px;
        }

        .RDSPagination__number__minimum {
          justify-content: right;
          margin-bottom: 10px;
        }
      }
    }
  }
}
