.Modelupload {
  display: flex;
  flex-direction: column;
  gap: 35px;

  &__upload {
    flex: 1;
    text-align: center;

    &__header {
      margin-top: 62px;
      margin-bottom: 16px;
      font-family: $font;
      text-align: center;
      color: $text-primary;
      @include md-21-extrabold;
    }

    &__wrapper {
      position: relative;
      height: 244px;
      background: #ffffff;
      border: 1px dashed #6073ff;
      border-radius: 24px;

      &__inner {
        margin-top: 55px;

        &__button {
          position: relative;

          .RDSIconTextButton {
            height: 64px;
          }
        }
      }

      &__text {
        margin-top: 20px;
        padding-left: 5px;
        font-family: $font;
        font-style: normal;
        text-align: left;
        color: $text-secondary;
        @include sm-14-semibold;

        > div {
          margin-top: 60px;
        }
      }
    }
  }

  &__viewer {
    flex: 1;

    &__text {
      text-align: center;
      font-family: $font;
      font-style: normal;
      color: $text-primary;
      @include sm-14-bold;
    }

    &__wrapper {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 32px;

      &__paper {
        height: calc(100% - 32px);
        background: #ffffff;
        box-shadow: 0px 8px 16px rgba(96, 115, 255, 0.16);
        border-radius: 20px;
        position: relative;

        &__header {
          width: 94px;
          height: 34px;
          position: absolute;
          top: 12px;
          left: 16px;
          background: #f2f4fc;
          box-shadow: 0px 8px 16px rgba(96, 115, 255, 0.16);
          border-radius: 8px;
          font-family: $font;
          font-style: normal;
          color: $bi-primary1;
          text-align: center;
          @include sm-14-extrabold;
          line-height: 34px;
        }

        &__image {
          width: 100%;
          box-shadow: 0px 8px 16px rgba(96, 115, 255, 0.16);
          border-radius: 20px;
        }

        &__QRCode {
          top: 0;
          padding: 10px;
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          &__Wrapper {
            width: 180px;
            height: 180px;
            background: #ffffff;
            padding: 10px;
            border-radius: 8px;
          }
        }

        &__text {
          margin-top: 10px;
          text-align: center;
          color: $text-placeholder;
          @include sm-12-semibold;
        }

        &__text-en {
          margin-top: 10px;
          text-align: center;
          color: $text-placeholder;
          font-size: 11px !important;
          @include sm-12-semibold;
        }
      }
    }
  }
}

.QRModal {
  width: 200px;
  height: 240px;
  border-radius: 8px;

  &__text {
    margin-top: 10px;
    font-family: $font;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    color: #6073ff;
  }
}

.align-center {
  margin: 0 auto;
}
