.Detail3DViewer {
  margin-top: 140px;

  &__modal {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-family: $font;
    font-style: normal;
    color: rgba(0, 0, 0, 0.87);
    @include lg-24-extrabold;
  }

  &__contents {
    margin-top: 32px;
    display: flex;
    flex-direction: row;

    &__wrapper {
      position: relative;

      &__dropdown {
        height: 56px;
      }

      &__dropdown:nth-child(2) {
        margin-top: 10px;
      }

      &__copy {
        position: absolute;
        bottom: 0;

        &__wrapper {
          width: 240px;
          display: flex;
          margin-top: 10px;
          gap: 6px;
          align-items: center;
          justify-content: center;

          &__text {
            font-family: $font;
            font-style: normal;
            text-align: center;
            color: $bi-secondary1;
            @include sm-12-semibold;
          }
        }

        &__wrapper-en {
          width: 310px;
          display: flex;
          margin-top: 10px;
          gap: 6px;
          align-items: center;
          justify-content: center;

          &__text {
            font-family: $font;
            font-style: normal;
            text-align: center;
            color: $bi-secondary1;
            @include sm-12-semibold;
          }
        }
      }
    }

    &__viewer {
      &__waiting {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border-radius: 2px;
        @include md-21-extrabold;
      }
    }
  }

  &__Control {
    margin-top: 16px;
    width: 100%;
    padding: 24px 32px;
    border: 4px solid #ffffff;
    border-radius: 10px;
    display: flex;
    gap: 60px;
    font-family: $font;

    &__Title {
      color: $grey-500;
      @include md-21-extrabold;
    }

    &__Detail {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__Text {
        display: flex;
        gap: 24px;
        @include md-16-bold;

        &__Sub {
          color: $text-secondary;
          @include sm-14-medium;
        }
      }

      &__TextEn {
        display: flex;
        gap: 13px;
        @include md-16-bold;
      }
    }
  }

  &__Capture {
    position: relative;
    margin-top: 24px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 90px;

    &__Background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 0;
      background: url('../images/DetailCaptureBackground.png');
      background-size: contain;
      border-radius: 0 8px 8px 0;
    }

    &__Text {
      padding: 42px 0 42px 62px;
      font-family: $font;

      &__Title {
        color: $text-primary;
        @include lg-24-bold;
      }

      &__Sub {
        color: $text-secondary;
        @include sm-14-bold;
      }
    }

    &__Images {
      margin-right: 30px;
      display: flex;
      align-items: center;
      z-index: 1;

      img {
        height: fit-content;
      }
    }

    &__Buttons {
      margin: 14px 96px 14px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }
  }

  &__Export {
    margin-top: 24px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &__Contents {
      display: flex;
      gap: 40px;
      font-family: $font;

      &__Text {
        text-align: right;
        padding: 24px;

        &__Title {
          color: $text-primary;
          @include lg-24-bold;
        }

        &__Sub {
          margin-top: 4px;
          color: $text-secondary;
          @include sm-14-bold;
        }
      }

      &__Buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 18px;
      }

      &__Separator {
        height: 1px;
        background: rgba(12, 13, 13, 0.08);
      }

      &__More {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: $font;

        &__Contents {
          display: none;
          background: #eeeff2;
          height: 482px;

          &__Images {
            flex: 4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
          }

          &__Text {
            padding-top: 66px;
            flex: 6;
            display: flex;
            flex-direction: column;
            color: #646466;
            @include sm-12-medium;

            &__Title {
              @include sm-14-extrabold;
            }

            &__Ex {
              @include sm-14-semibold;
            }
          }
        }

        .active {
          display: flex;
          gap: 55px;
        }
      }
    }
  }
}
