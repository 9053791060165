.PaymentPlan {
  padding: 57px 8.3333% 18px 8.3333%;
  gap: 24px;
  overflow-x: hidden;

  &__Wrapper {
    width: 1296px;
    display: flex;
    gap: 32px;
  }

  &__item {
    position: relative;
    width: 300px;
    height: 514px;
    padding: 24px;
    background: $grey-10;
    border-radius: 24px;
    color: $grey-950;
    font-family: $font;
    font-style: normal;

    &__inUse {
      color: $bi-primary1;
      border: 3px solid $bi-primary1;
    }
    &__inUse &__explain {
      color: $bi-primary2;
    }

    &__title {
      @include lg-40-extrabold;
    }

    &__price {
      margin-top: 40px;
      color: $grey-600;
      display: flex;
      align-items: center;
      padding: 0px;
      gap: 16px;

      div:nth-child(1) {
        @include md-16-semibold;
      }
      div:nth-child(2) {
        @include lg-24-extrabold;
      }

      &__enterpriseEn {
        margin-top: 15px;
        flex-direction: column;
        gap: 13px;
        align-items: flex-start;
      }

      &__discount {
        width: 181px;
        position: absolute;
        top: -80%;
        left: 0;
        color: $notification-warning-dark;
        font-family: $font;
      }
    }

    &__explain {
      margin-top: 10px;
      display: flex;
      align-items: center;
      @include md-16-extrabold;
    }

    &__list {
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding: 0px;
      gap: 8px;
      color: $grey-950;
      @include sm-14-semibold;
    }
  }

  &__itemEn {
    height: 600px;
  }

  &__Modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font: $font;

    &__content {
      width: 70%;
      padding: 0 12px;

      .row {
        &:first-child {
          @include md-18-extrabold;
        }

        .col-xl-2 {
          background: white;
          padding: 15px;
          border: $grey-50 0.1px solid;

          &:not(:nth-child(1)) {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

.PlusPaymentModal__SupportingText {
  margin-bottom: 10px;
  font-family: $font;
  font-style: normal;
  @include md-16-medium;
}

.PlusPaymentModal__LinkText {
  font-family: $font;
  font-style: normal;
  color: $text-primary;
  @include md-16-medium;

  &__Title {
    @include md-16-extrabold;
  }
}

.PlusPaymentModal__AdditionalContents {
  margin-top: 10px;
  font-family: $font;
  font-style: normal;
  @include md-21-semibold;
}
