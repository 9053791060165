.PaymentInfo {
  padding: 0 8.3333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 180px;

  &__item {
    width: 55%;

    &__cardNum {
      padding: 10px;
      gap: 16px;
      letter-spacing: 2px;
    }
  }

  &__item:nth-child(1) {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  &__item:nth-child(2) {
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f6f6fa;
    border-radius: 16px;
    padding: 20px 25px;
    gap: 30px;
    @include md-16-bold;
  }

  &__item:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    @include sm-12-semibold;
  }
}
