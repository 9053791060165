.DetailPageARviewer {
  font-family: $font;

  &__Title {
    color: $text-primary;
    @include lg-40-extrabold;
  }

  &__Contents {
    display: flex;
    justify-content: center;
    align-items: center;

    &__Header {
      position: relative;
      color: $bi-primary1;
      @include md-21-extrabold;

      &__Underline {
        z-index: 1;
        position: relative;
        left: -1px;
        bottom: 14px;
        height: 6px;
        width: 32px;
        background: $bi-secondary2;
      }
    }

    &__Sub {
      margin-top: 8px;
      color: $text-primary;
      @include md-16-semibold;
    }
  }

  &__Control {
    border: 1px solid white;
    margin-top: 45px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &__Contents {
      display: flex;
      gap: 40px;
    }

    &__Images {
      img {
        border-radius: 8px 0 0 0;
      }
    }

    &__Text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__Title {
        color: $text-primary;
        @include lg-24-bold;
      }

      &__Sub {
        color: $text-secondary;
        @include sm-14-bold;
      }
    }

    &__Size {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__Title {
        text-align: center;
        color: $text-primary;
        @include md-16-bold;
      }

      &__Sub {
        margin-top: 10px;
        display: flex;
      }
    }

    &__Move {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__Title {
        text-align: center;
        color: $text-primary;
        @include md-16-bold;
      }

      &__Sub {
        margin-top: 10px;
        display: flex;
      }
    }

    &__Separator {
      height: 1px;
      background: rgba(12, 13, 13, 0.08);
    }

    &__More {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__Contents {
        padding: 40px;
        justify-content: center;
        width: 100%;
        background: #eeeff2;
        gap: 70px;
        display: none;

        &.active {
          display: flex;
        }

        &__Size {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 270px;

          &__Header {
            margin-bottom: 20px;
            color: $text-primary;
            @include md-16-bold;
          }

          &__Sub {
            margin-top: 20px;
            color: $text-secondary;
            @include sm-12-medium;
          }
        }
      }
    }
  }

  &__Wrapper {
    margin-top: 45px;
    margin-bottom: 100px;
    display: flex;

    &__QRCode {
      position: relative;
      width: 100%;
      height: 280px;
      display: flex;
      gap: 60px;
      align-items: center;
      box-shadow: 0px 8px 16px rgba(96, 115, 255, 0.16);
      border-radius: 16px;
      padding: 40px 32px;
      background: linear-gradient(90deg, #d9ddff 0%, #d9faff 100%) #ffffff;
      background-size: 38.7%;
      background-repeat: no-repeat;

      &__Back {
        position: absolute;
        left: 19px;
        bottom: 32px;
        color: #ffffff;
        font-family: $font;
        font-style: normal;
        font-weight: 800;
        line-height: 100px;

        @include for-tablet-portrait-up {
          font-size: 70px;
        }

        @include for-tablet-landscape-up {
          font-size: 80px;
        }

        @include for-desktop-up {
          font-size: 90px;
        }
      }

      &__Header {
        color: $text-primary;
        @include md-16-extrabold;
      }

      &__QR {
        margin-left: 27%;
        border-radius: 16px;
        width: 214px;
        height: 214px;
        background-image: linear-gradient(90deg, #6073ff 0%, #60ecff 100%);
        display: flex;
        justify-content: center;
        align-items: center;

        &__Back {
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          background: #ffffff;
          border-radius: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__Sub {
        margin-top: 16px;
        color: $text-secondary;
        @include sm-12-semibold;
      }
    }

    &__Buttons {
      margin-top: 8px;
      display: flex;
      gap: 8px;
    }
  }
}
