.Login {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;

  &__inner {
    padding: 0 28px 0 28px;
    width: 384px;
    margin: 0 auto;
    position: relative;

    &__title {
      img {
        margin: 0 auto;
      }
    }

    &__signup {
      margin-top: 22px;
      text-align: center;
      font-family: $font;
      font-style: normal;
      color: $text-primary;
      @include sm-14-medium;

      button {
        margin-left: 20px;
        font-family: $font;
        font-style: normal;
        color: #6073ff;
      }
    }

    &__input {
      margin-top: 18px;
      &__email {
        margin-bottom: 8px;
      }

      &__fail {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      &__option {
        position: relative;
        height: 60px;

        &__login {
          position: absolute;
          left: 8px;
          top: 16px;

          &__text {
            font-family: $font;
            font-style: normal;
            color: $text-primary;
            @include sm-14-medium;
          }
        }

        &__password {
          position: absolute;
          right: 8px;
          top: 16px;
          font-family: $font;
          font-style: normal;
          color: $text-secondary;
          cursor: pointer;
          @include sm-14-semibold;
        }
      }
    }

    &__oauth {
      margin-top: 50px;
      display: flex;
      flex-direction: row;

      &__separator {
        width: 100px;
        border-bottom: 1px solid #0c0d0d14;
        margin-bottom: 10px;
      }

      &__header {
        padding: 0 19px;
        font-family: $font;
        font-style: normal;
        color: $text-secondary;
        @include sm-12-semibold;
      }

      &__header-en {
        padding: 0 56px;
        font-family: $font;
        font-style: normal;
        color: $text-secondary;
        @include sm-12-semibold;
      }
    }

    &__platform {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      gap: 36px;

      &__container {
        cursor: pointer;

        &__name {
          margin-top: 8px;
          font-family: $font;
          font-style: normal;
          text-align: center;
          color: $text-secondary;
          @include sm-12-semibold;
        }
      }
    }

    &__footer {
      white-space: nowrap;
      margin-top: 20px;
      font-family: $font;
      font-style: normal;
      text-align: center;
      color: #000000;
      @include sm-12-semibold;

      a {
        color: $bi-primary1;
      }
    }

    &__i18n {
      margin-top: 30px;
      float: right;
    }
  }
}
