.Analysis {
  padding-top: 59px;

  &__TabInfo {
    font-family: $font;
    font-style: normal;
    @include md-16-medium;
    margin-top: 8px;
  }

  &__Allmodels {
    &__Radio {
      display: flex;
      gap: 32px;
      padding: 10px 0;
      margin-top: 8px;
    }

    &__Period {
      margin-top: 22px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__Usage {
    display: flex;
    align-items: center;
    gap: 32px;

    &__Mount {
      font-family: $font;
      font-style: normal;
      color: $states-primary-normal;
      @include md-18-extrabold;
    }

    &__Plan {
      font-family: $font;
      font-style: normal;
      color: $text-secondary;
      @include sm-14-semibold;
    }
  }

  &__Period {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__ChartWrapper {
    background: white;
    width: 100%;
    aspect-ratio: 1.8747;
    min-height: 455px;
    max-height: 575px;
  }

  &__Title {
    font-family: $font;
    font-style: normal;
    @include md-21-extrabold;
  }

  &__Info {
    margin-top: 56px;
    margin-bottom: 18px;

    &__Content {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      justify-content: end;
      align-items: center;

      &__Total {
        color: $states-primary-normal;
        @include md-18-extrabold;
      }

      &__Plan {
        color: $text-secondary;
        @include sm-14-semibold;
      }
    }
  }

  &__Paper {
    background: #ffffff;
    border-radius: 40px;
    height: 284px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 38px;

    &__Count {
      font-family: $font;
      font-style: normal;
      @include lg-70-extrabold;
    }

    &__Text {
      font-family: $font;
      font-style: normal;
      @include md-18-extrabold;
    }

    &__Text-All {
      color: $bi-primary1;
    }
  }

  /* 모델 데이터테이블 디자인 */
  &__ModelDataTable {
    margin: 40px 0 60px 0;
    font-family: $font;

    .rdt_Table {
      background: none;
    }

    .rdt_TableHeadRow {
      background: #eeeff2;
      border-radius: 6px;
      border-bottom-style: none !important;
      margin-bottom: 2px;
      color: $text-secondary;
      @include sm-14-bold;
    }

    .rdt_TableRow {
      margin: 2px 0 2px 0;
      border: none;
      border-radius: 6px;
      border-bottom-style: none !important;
      @include sm-14-medium;
    }
  }
}
