.UploadDone {
  display: flex;
  justify-content: space-between;
  padding: 10px 24px 12px 24px;
  background: $bg-lightgrey;

  &__name {
    color: $text-primary;
    @include sm-14-semibold;
  }

  &__state {
    display: flex;
    gap: 13px;
    color: $notification-success-main;
    @include sm-14-bold;
  }
}

.Separator {
  border-top: 2px solid $bg-lightgrey;
}
