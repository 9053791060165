.MyPage {
  width: calc(100vw - 220px);
  padding-top: 55px;

  &__i18n {
    &__divider {
      height: 2px;
      left: 576px;
      top: 429px;
      border-radius: 0px;
      background: #0c0d0d14;
      margin: 50px 0;
    }
  }

  &__title {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 36px;
    /* identical to box height, or 171% */

    letter-spacing: 0.25px;
    margin-bottom: 25px;
  }
  &__deletedUser {
    &__email {
      margin-top: 160px;
      margin-bottom: 120px;
    }
  }

  &__withdraw {
    font-family: 'Pretendard';
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #56a1f9;
    position: absolute;
    right: 0;
    cursor: pointer;
    align-items: center;
    display: flex;
    &__container {
      position: relative;
      margin-top: 20px;
      height: 50px;
    }
  }
  &__Oauth {
    display: flex;
    flex-direction: column;
    padding-top: 80px;

    &__title {
      text-align: center;
      font-family: 'Pretendard';
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      color: #646466;
      margin-bottom: 24px;
    }
    &__platform {
      margin: auto;
      margin-bottom: 70px;

      &__name {
        margin-top: 8px;
        font-family: 'Pretendard';
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.25px;
        text-align: center;
      }
    }
  }

  &__resetPassword {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__divider {
      height: 2px;
      left: 576px;
      top: 429px;
      border-radius: 0px;
      background: #0c0d0d14;
      margin-top: 30px;
    }
    &__button {
      margin-top: 30px;
    }
  }

  &__marketingAgree {
    position: absolute;
    display: flex;
    gap: 10px;
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.1px;
    margin-left: 10px;
    &__container {
      display: flex;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    &__container_oauth {
      display: flex;
      margin-top: 40px;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}

.RestoreUser {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.25px;
  text-align: center;
  margin-top: 20px;
  &__text {
    margin-bottom: 32px;
  }
}
