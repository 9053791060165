.DetailPageLinks {
  margin-top: 36px;
  margin-bottom: 70px;
  background: #eeeff2;
  border: 1px solid #e1e2e5;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1800px) {
    margin-left: 200px;
    margin-right: 200px;
  }

  &__Header {
    margin-top: 72px;
    display: flex;
    justify-content: center;
    gap: 64px;

    &__TextWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 18px;

      &__Title {
        z-index: 2;
        width: fit-content;
        position: relative;
        font-family: $font;
        font-style: normal;
        color: $bi-primary1;
        @include md-21-extrabold;

        &__Back {
          z-index: 1;
          position: relative;
          left: 35px;
          bottom: 15px;
          width: 72px;
          height: 6px;
          background: $bi-secondary2;
        }
      }

      &__Text {
        font-family: $font;
        font-style: normal;
        color: $text-secondary;
        @include md-16-semibold;
      }
    }
  }

  &__Wrapper {
    padding-top: 34px;
    padding-bottom: 24px;
    width: 100%;
    display: flex;
    gap: 160px;
    justify-content: center;

    &__Text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: $font;
      font-style: normal;

      &__Title {
        color: $text-primary;
        @include lg-24-bold;
      }

      &__Sub {
        color: $text-secondary;
        @include sm-14-bold;
      }
    }

    &__Buttons {
      margin-top: 24px;
      display: flex;
      gap: 10px;
    }
  }

  &__More {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font;
    font-style: normal;
    @include sm-12-semibold;

    &__Separator {
      width: 100%;
      height: 1px;
      background: #0c0d0d14;
    }

    &__Contents {
      padding: 40px;
      background: #ffffff;
      display: none;
      justify-content: center;
      gap: 53px;

      &__Text {
        font-family: $font;
        font-style: normal;

        &__Title {
          @include md-18-extrabold;
        }

        &__Sub {
          @include md-16-semibold;

          &__En {
            font-size: 13px;
          }
        }
      }

      &__Icons {
        font-family: $font;
        font-style: normal;
        @include sm-14-bold;

        &__Shadow {
          filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.13));
        }
      }
    }
  }

  .active {
    display: flex;
  }
}
