.Dashboard {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include for-phone-only {
    flex-direction: column;
  }

  &__contents {
    min-height: 100vh;
    width: calc(100% - 200px);

    @include for-phone-only {
      width: 100%;
    }
  }

  &__MobileNavigator {
    display: none;

    @include for-phone-only {
      padding: 22px 28px;
      display: block;
      background: #ffffff;
      width: 100%;
      height: 60px;
    }
  }
}
