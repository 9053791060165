.Uploadresult {
  &__model {
    padding-top: 60px;

    &__text {
      font-family: $font;
      text-align: center;
      @include lg-24-extrabold;
    }

    &__card {
      margin-top: 42px;
      flex: 1;
      display: flex;
      gap: 16px;
      justify-content: center;
    }
  }

  &__upload {
    margin-top: 80px;
    flex: 2;
    display: flex;
    flex-direction: column;

    &__wrapper {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__text {
        margin-top: 15px;
        font-family: $font;
        text-align: center;
        color: $text-secondary;
        @include sm-14-semibold;
      }
    }
  }
}
