.SignupComplete {
  width: 384px;
  height: 100%;
  margin: 0 auto;

  &__IconArea {
    margin-top: 210px;
    display: flex;
    justify-content: center;
  }

  &__header {
    margin-top: 27px;
    font-family: 'Pretendard', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #0c0d0d;
  }

  &__text {
    margin-top: 8px;
    font-family: 'Pretendard', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #646466;
  }
}

.SignupComplete button {
  margin-top: 44px;
}
